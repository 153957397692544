var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"emailInfo-container page-block",attrs:{"id":"kapcsolat"}},[_c('h2',{staticClass:"text-center text-uppercase"},[_vm._v(" "+_vm._s(_vm.tr('contact-title')))]),_c('p',{staticClass:"text-center mb-5",staticStyle:{"color":"#A72585!important"},domProps:{"innerHTML":_vm._s(_vm.tr('contact-text'))}}),_c('div',{staticClass:"container-fluid justify-content-center row m-0"},[_c('div',{staticClass:"m-a col-12 col-md-6"},[(!_vm.formStatus)?_c('validationObserver',{ref:"contactObserver",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{},[_c('form',{staticClass:"form form--codefilling",attrs:{"id":"contactForm"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.formSubmit)}}},[_c('div',{staticClass:"forename-row w-100 input--info"},[_c('ValidationProvider',{attrs:{"rules":"required","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
var errors = ref.errors;
return [_c('label',{staticClass:"form-label email-row",attrs:{"for":"name"}},[_vm._v(_vm._s(_vm.tr('contact-input-name'))),_c('span',{staticClass:"ml-1 error"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control email-placeholder",attrs:{"placeholder":_vm.tr('contact-name-placeholder'),"type":"text","id":"name"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}}),(_vm.getErrorMessage('name', Object.entries(failedRules)[0]))?_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.getErrorMessage("name", Object.entries(failedRules)[0])))]):_c('div',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"email-row w-100 input--info"},[_c('ValidationProvider',{attrs:{"rules":"required|email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
var errors = ref.errors;
return [_c('label',{staticClass:"form-label mt-4 email-row",attrs:{"for":"recipient-name"}},[_vm._v(_vm._s(_vm.tr('contact-input-email'))),_c('span',{staticClass:"ml-1 error"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control email-placeholder",attrs:{"placeholder":_vm.tr('contact-email-placeholder'),"type":"text","id":"recipient-name"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),( _vm.getErrorMessage('email', Object.entries(failedRules)[0]))?_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.getErrorMessage("email", Object.entries(failedRules)[0])))]):_c('div',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"email-row w-100 input--info"},[_c('ValidationProvider',{attrs:{"rules":"required","vid":"topic"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
var errors = ref.errors;
return [_c('label',{staticClass:"form-label mt-4 email-row",attrs:{"for":"topic"}},[_vm._v(_vm._s(_vm.tr('contact-select-title'))),_c('span',{staticClass:"ml-1 error"},[_vm._v("*")])]),_c('b-form-select',{attrs:{"name":"topic"},model:{value:(_vm.form.topic),callback:function ($$v) {_vm.$set(_vm.form, "topic", $$v)},expression:"form.topic"}},[_c('b-form-select-option',{attrs:{"value":null}},[_vm._v(_vm._s(_vm.tr('contact-form-choose-topic')))]),_c('b-form-select-option',{attrs:{"value":_vm.tr('contact-select-promocio')}},[_vm._v(_vm._s(_vm.tr('contact-select-promocio')))]),_c('b-form-select-option',{attrs:{"value":_vm.tr('contact-select-product')}},[_vm._v(_vm._s(_vm.tr('contact-select-product')))])],1),( _vm.getErrorMessage('topic', Object.entries(failedRules)[0]))?_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.getErrorMessage("topic", Object.entries(failedRules)[0])))]):_c('div',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('ValidationProvider',{staticClass:"message-row",attrs:{"rules":"required","vid":"message"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
var errors = ref.errors;
return [_c('label',{staticClass:"form-label mt-4 ",attrs:{"for":"message-text"}},[_vm._v(_vm._s(_vm.tr('contact-input-msg'))),_c('span',{staticClass:"ml-1 error"},[_vm._v("*")])]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.message),expression:"form.message"}],staticClass:"form-control textarea",attrs:{"type":"text","placeholder":_vm.tr('contact-msg-placeholder'),"id":"message-text"},domProps:{"value":(_vm.form.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "message", $event.target.value)}}}),(_vm.getErrorMessage('message', Object.entries(failedRules)[0]))?_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.getErrorMessage("message", Object.entries(failedRules)[0])))]):_c('div',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(!_vm.formStatus)?_c('div',{staticClass:"d-flex justify-content-center mt-4"},[_c('button',{staticClass:"btn btn btn-primary ",attrs:{"type":"submit","disabled":_vm.formSubmitted}},[_vm._v(_vm._s(_vm.tr('contact-btn')))])]):_vm._e()],1)])]}}],null,false,1458644546)}):_vm._e(),(_vm.formStatus === true)?_c('h2',{staticClass:"text-center pb-2 mt-2",staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(_vm.tr('contact-form-result-success'))+" ")]):_vm._e(),(_vm.formStatus === true)?_c('div',{staticClass:"d-flex justify-content-center"},[_c('button',{staticClass:"btn btn-primary m-auto",attrs:{"type":"button"},on:{"click":function($event){_vm.defaultFormState(); _vm.formStatus = false}}},[_vm._v(" "+_vm._s(_vm.tr('contact-form-back'))+" ")])]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }