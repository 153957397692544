<template>    
  <b-navbar toggleable="lg" sticky :class="{'scrolled': scrollDown}">
    <b-container fluid='xl'>
      <b-row>
        <b-col cols="12">    
            <div class="d-flex align-items-center w-100 flex-wrap">
              <!-- LOGO -->
              <router-link :to="{name:'APCodeUpload'}" class="navbar-brand">
                <img src="@/assets/img/design/logo.svg" alt="Snickers">
              </router-link>
              <b-navbar-toggle @click="isOpen=!isOpen; setGTM('menu_gomb')" target="nav-collapse" class="p-0 ml-auto">
                <div
                    class="custom-hamburger"
                    :class="{ 'custom-hamburger-close' : isOpen }">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </b-navbar-toggle>
              <!-- MENU -->
              <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="w-100 d-lg-flex align-center justify-content-end">
                  <b-nav-item class="px-2"
                      @click="isOpen=false;setGTM(item.name)"
                      v-for="item in items" :key="'item' + item.id"                      
                      v-scroll-to="{ el: item.link, offset: -80 }"
                  >
                    {{ item.name }}
                  </b-nav-item>
                </b-navbar-nav>      
              </b-collapse>
            </div>
          </b-col>
      </b-row>
    </b-container>
  </b-navbar>
</template>

<script>
export default {
  data() {
    return {
      scrollDown:null,
      isOpen: false     
    }
  },
  computed:{
    items(){
      return [
        {
          'id': 1,
          'name': this.tr('menu-nyeremenyek'),
          'link': '#nyeremenyek'
        },
        {
          'id': 2,
          'name': this.tr('menu-jatek-menete'),
          'link': '#jatekmenete'
        },
        {
          'id': 3,
          'name': this.tr('menu-kodfeltoltes'),
          'link': '#form'
        },       
        {
          'id': 4,
          'name': this.tr('menu-reszt-vevo-termekek'),
          'link': '#termekek'
        },
        {
          'id': 5,
          'name': this.tr('menu-nyertesek'),
          'link': '#nyertesek'
        }       
      ]
    } 
  },
  methods: {
    setGTM(item){
      this.GTtrackGA4({
        'event' : 'event',
        'category': 'fejlec',
        'action': 'kattintas',
        'label': item,
        'menu': 'fejlec',
        'clicked_text':item,
        'action_type': 'menu_kattintas'
      })
    },
    scrollDirection(){   
          let _this = this         
                      
          var st = window.pageYOffset || document.documentElement.scrollTop; 
          if (st > this.lastScrollTop){
              // downscroll
              // console.log('scroll down')
              _this.scrollDown = true;
          }else if(st == 0){
              _this.scrollDown = false;
          }// else {
              // upscroll 
              // console.log('scroll up')
             /// _this.scrollDown = true
          //}
          this.lastScrollTop = st <= 0 ? 0 : st;             
      },
  },
  mounted(){
    let _this = this

    window.addEventListener("scroll", function(){
      _this.scrollDirection()
    }, false);
  }
}
</script>