<template>
  <div class="winners page-block position-relative" id="winners-block">
    <div class="container-xl">
      <div class="row">
        <div class="col-12 ">
          <div class="winners-block__inner">
            <h2>{{tr('winners-title')}}</h2>
            <!-- TAB -->
            <div class="winners-button-select" >
              <!--<button 
                :class="{'active':winnerState === 'weekly'}" 
                v-on:click="setWinnerState('weekly');setGTM('nyertesek_weekly')"
              >
                {{tr('winners-tab-1')}}
              </button>              
              <button 
                :class="{'active':winnerState === 'main'}" 
                v-on:click="setWinnerState('main');setGTM('nyertesek_main')"
              >{{tr('winners-tab-2')}}
              </button>-->
            </div>
       

            <!-- TABLE -->
            <div class="winners-wrap">
              <div class="winners__item">
                <table cellspacing="0" cellpadding="0" border="0"  class="winners__block "  >
                  <thead>
                    <tr class="winners-top">
                      <th>{{tr('winners-table-th-1')}}</th>
                      <th>{{tr('winners-table-th-2')}}</th>
                      <th>{{tr('winners-table-th-3')}}</th>
                    </tr>
                  </thead>
                  <tbody v-if="filteredWinners && filteredWinners.length">
                      <tr class="gutter-td">
                        <td colspan="3"></td>
                      </tr>
                      <tr class="winners-list" v-for="(winner, winnerindex) in filteredWinners" :key="winnerindex">
                        <td class="winners-id">
                          <div><strong>{{ winner.name }}</strong></div> 
                          <div class="d-md-none">{{winnings[winnerState]}}</div>
                        </td>
                        <td v-if="winner.type == 'main'" class="winner-type-td d-none d-md-table-cell ">                          
                          {{winnings.main}}
                        </td>
                        <td v-else class="winner-type-td d-none d-md-table-cell ">                          
                          {{winnings.weekly}}
                        </td>
                        <td class="winners-product text-right text-md-left">
                         <span>{{winner.winning_time}}</span>
                        </td>

                      </tr>
                      <tr class="gutter-td">
                        <td colspan="3"></td>
                      </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td v-if="winnerState != 'main'" class="no-winner" colspan="4">
                        {{tr("winners-empty-table-weekly")}}
                      </td>
                      <td v-if="winnerState == 'main'" class="no-winner" colspan="4">
                        {{tr("winners-empty-table-main")}}  
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-8 offset-lg-2">
          <p class="winners-more-text">{{tr("winners-info-text")}}  </p>
        </div>
      </div>
    </div>    
  </div>
</template>

<script>
import promoStatus from "@/config/promoStatus";

export default {
  data() {
    return {
      winnerState: "",
      winnerTypes: {
        napi: "Napi nyertes",
        weekly: "weekly nyertes",
        main: 'Fődíj nyertes'
      },
      winnings:{
        weekly: this.tr('winning-type-weekly'),
        main: this.tr('winning-type-main')
      }
    }
  },
  computed: {

    promotionIsActive() {
      return this.$store.state.promoStatus === promoStatus.live;
    },
    promotionIsBefore() {
      return this.$store.state.promoStatus === promoStatus.before;
    },
    promotionIsAfter() {
      return this.$store.state.promoStatus === promoStatus.after;
    },
    filteredWinners() {
      return this.$store.state.winnersAll;
      //return (this.$store.state.winnersAll ?? []).filter(w => w.type === this.winnerState)
    }
  },
  created() {
    this.$store.dispatch("getWinners");    
  },
  components: {},
  methods: {
    setGTM(item){

      this.GTtrackGA4({
        'event' : 'event',
        'category': 'nyertesek',
        'action': 'kattintas',
        'label': item,
        'clicked_text':item,
        'action_type': 'nyertesek_kattintas'
      })
    },
    setWinnerState(newState) {
      this.winnerState = newState;      
    },
  }
}
</script>